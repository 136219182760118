.App {
  font-family: sans-serif;
}

:root {
  --sideBarMargin: 4.5rem;
}

body {
  background-color: #2e2e2e;
}

.main {
  margin-left: var(--sideBarMargin);
}

/* Message Bar Styling*/

[contentEditable="true"]:empty:before {
  opacity: 0.5;
  font-size: 1.125rem;
  font-family: sans-serif;
  content: attr(data-text);
}

.signInButton {
  position: absolute;
  bottom: 2rem;
  left: 0;
  background-color: #3d3d3d;
  border: none;
  outline: none;
  color: #9e9e9e;
  border-radius: 0.5rem;
  height: 1.25rem;
  width: 4rem;
  margin: 0.25rem;
}
.signOutButton {
  position: absolute;
  display: none;
  bottom: 2rem;
  left: 0;
  background-color: #3d3d3d;
  border: none;
  outline: none;
  color: #9e9e9e;
  border-radius: 0.5rem;
  height: 1.25rem;
  width: 4rem;
  margin: 0.25rem;
}

.messageInput {
  border-radius: 0.5rem;
  position: relative;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.625rem;
  min-height: 1.25rem;
  left: 0.25rem;
  width: calc(97% - 2.375rem);
  font-size: 1.125rem;
  font-family: sans-serif;
  resize: none;
  overflow: hidden;
  background-color: #3d3d3d;
  border: none;
  outline: none;
  overflow-wrap: break-word;
  color: #dbdee6;
}

.inputBar {
  font-size: 1.125rem;
  border-radius: 0.5rem;
  position: absolute;
  min-height: 2.25rem;
  bottom: 1.25rem;
  left: 1rem;
  width: calc(100% - 2.25rem);
  background-color: #3d3d3d;
  border: none;
  outline: none;
}

.sendIcon {
  position: absolute;
  padding: 0.25rem;
  right: 0.25rem;
  width: 1.875rem;
  height: 1.875rem;
  border: none;
  outline: none;
  z-index: 1;
  opacity: 0.8;
}
.sendIcon:hover {
  opacity: 1;
}
.sendIcon:active {
  opacity: 0.5;
}

/* Side Bar Styling */

.sidebar {
  height: 100%;
  width: var(--sideBarMargin);
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #1f1f1f;
  overflow-x: hidden;
  padding-top: 20px;
  text-align: center;
}

.serverAddIcon {
  background-color: #3d3d3d;
  width: 3.5rem;
  height: 3.5rem;
  position: relative;
  border-radius: 50%;
  margin-left: auto;
  margin-top: 0.5rem;
  font-size: 2.5rem;
  color: #1e873d;
  outline: none;
  border: none;
  transition: 0.25s;
}

.serverAddIcon:hover {
  border-radius: 25%;
  background-color: #1e873d;
  color: #ffffff;
}

.serverAddIcon:active {
  transform: translateY(4px);
}

.serverBreak {
  width: 75%;
  border: none;
  height: 0.125rem;
  color: #3d3d3d;
  background-color: #3d3d3d;
}

.serverIcon {
  background-color: #3d3d3d;
  width: 3.5rem;
  height: 3.5rem;
  position: relative;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  color: #1e873d;
  outline: none;
  border: none;
  transition: 0.25s;
}
.serverIcon:hover {
  border-radius: 25%;
}
.serverIcon:active {
  transform: translateY(4px);
}

/*.sideBarButton {
  position: absolute;
  left: -5px;
  height: 40px;
  width: 25px;
  border-radius: 5px;
  background-color: #4d4d4d;
  border: none;
  outline: none;
  opacity: 0.8;
  transition: .5s;
}

.sideBarButton:hover {
  opacity: 1;
  border-radius:
}

.sideBarButton:active {
  opacity: 0.5;
}*/
